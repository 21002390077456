import {
  Button,
  ButtonVariant,
  Select,
  SelectSize,
  Label,
  LabelSize,
  Icon,
  IconName,
  Tooltip,
  ButtonIconAlign,  
} from "@eightfold.ai/octuple";
import React, { useState, useEffect, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import formData from "../../utils/form-data";
import dayjs from 'dayjs';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { useNavigate } from "react-router";
import { globalContext } from "../../store";
import { updateDemographicData } from '../../commons/commonFunctions'
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);


const Personal = (props: any) => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);

  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [lgbtq, setLgbtq] = useState('');
  const [diversityNonSpecific, setDiversityNonSpecific] = useState('');
  const [veteranStatus, setVeteranStatus] = useState('');
  const [education, setEducation] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [age, setAge] = useState<any>('');

  const [errors, setErrors] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState(false)
  const toooltipText = 'Refers to individuals who identify as diverse but either do not fall into one of the above categories (could include but is not limited to veteran status or disability), or do not wish to share further information with the firm as to how they are diverse.'
  const [showLoader, setShowLoader] = useState(false);
  const [ethnicityOptions, setEthnicityOptions] = useState<any>([]);

  useEffect(() => {
    if (props) {
      setGender(props?.gender);
      setEthnicity(props?.ethnicity);
      setLgbtq(props?.lgbtq);
      setDiversityNonSpecific(props?.diversityNonSpecific);
      setVeteranStatus(props?.veteranStatus);
      setEducation(props?.education);
      setCountryOfResidence(props?.countryOfResidence);
      setEthnicityOptions(formData.ethnicity(props?.countryOfResidence));
     setAge(props?.age)
    }
  }, [props]);

  const changeCountry = (e: any) => {
    setCountryOfResidence(e?.[0]);
    setEthnicityOptions(formData.ethnicity(e?.[0]));
  }

  const validateFormData = () => {
    const genderMsg = validateFields.validate({ dropDown: gender || '' }, validationOptions)?.error?.details[0]?.message;
    const ethnicityMsg = validateFields.validate({ dropDown: ethnicity || '' }, validationOptions)?.error?.details[0]?.message;
    const countryOfResidenceMsg = validateFields.validate({ dropDown: countryOfResidence || '' }, validationOptions)?.error?.details[0]?.message;
    let lgbtqMsg;
    let veteranStatusMsg;
    if (countryOfResidence === 'United States of America (the)' || countryOfResidence === 'United Kingdom of Great Britain and Northern Ireland (the)') {
      lgbtqMsg = validateFields.validate({ dropDown: lgbtq || '' }, validationOptions)?.error?.details[0]?.message;
    }

    if (countryOfResidence === 'United States of America (the)') {
      veteranStatusMsg = validateFields.validate({ dropDown: veteranStatus || '' }, validationOptions)?.error?.details[0]?.message;
    }

    if (genderMsg || countryOfResidenceMsg || ethnicityMsg || lgbtqMsg || veteranStatusMsg) {
      setErrors({
        genderMsg: genderMsg,
        genderStatus: chkForError(genderMsg),
        ethnicityMsg: ethnicityMsg,
        ethnicityStatus: chkForError(ethnicityMsg),
        countryOfResidenceMsg: countryOfResidenceMsg,
        countryOfResidenceStatus: chkForError(countryOfResidenceMsg),
        lgbtqMsg: lgbtqMsg,
        lgbtqStatus: chkForError(lgbtqMsg),
        veteranStatusMsg: veteranStatusMsg,
        veterenStatusError: chkForError(veteranStatusMsg),
      });
      return true;
    } else {
      return false;
    }
  };

  const savePersonalData = () => {
    if (validateFormData()) return;
    const payload = {
      isProfileCompleted: true,
      demographicSurveyData: {
        gender: gender,
        ethnicity: ethnicity,
        lgbtq: lgbtq,
        diversityNonSpecific: diversityNonSpecific,
        veteranStatus: veteranStatus,
        education: education,
        countryOfResidence: countryOfResidence,
        age: age,
      }
    };

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        gender: gender,
        ethnicity: ethnicity,
        lgbtq: lgbtq,
        diversityNonSpecific: diversityNonSpecific,
        veteranStatus: veteranStatus,
        education: education,
        countryOfResidence: countryOfResidence,
        age: age,
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext, isProfileCompleted: true, };
    const redirectUrl = '/dashboard';
    updateDemographicData({payload, leaderProfileId : props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader});
  };

  const backtoCompanyPage = () => {
    navigate('/register-company');
  }

  const chkForError = (condition: any) => {
    if (condition) {
      return 'error';
    } else {
      return 'validating';
    }
  }

  return (
    <div className="accountWrapper">
      <h1>Personal</h1>
      <h2>Personal Details</h2>

      {props?.countryOfResidenceOptions?.length > 0 &&
        <>
          <div className="fieldWrap">
            <Label
              htmlFor=""
              size={LabelSize.Medium}
              text="What is your country of residence?"
            />
            <Select
              onOptionsChange={(e: any) => {
                changeCountry(e)
              }}
              filterable
              options={props?.countryOfResidenceOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.countryOfResidence}
              status={errors?.countryOfResidenceStatus}
            />
            {errors?.countryOfResidenceMsg && (
              <span className="errorMessage">{errors?.countryOfResidenceMsg}</span>
            )}
          </div>

          {ethnicityOptions?.length > 0 &&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="What is your ethnicity?" />
              <Select
                onOptionsChange={(e: any) => {
                  setEthnicity(e?.[0]);
                }}
                options={ethnicityOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.ethnicity}
                status={errors?.ethnicityStatus}
              />
              {errors?.ethnicityMsg && (
                <span className="errorMessage">{errors?.ethnicityMsg}</span>
              )}
            </div>
          }

          <div className="fieldWrap">
            <Label htmlFor="" size={LabelSize.Medium} text="What is your gender?" />
            <Select
              onOptionsChange={(e: any) => {
                setGender(e?.[0]);
              }}
              options={props?.genderOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.gender}
              status={errors?.genderStatus}
            />
            {errors?.genderMsg && (
              <span className="errorMessage">{errors?.genderMsg}</span>
            )}
          </div>
          
          <div className="fieldWrap" style={{ display: (countryOfResidence === "United States of America (the)" || countryOfResidence === "United Kingdom of Great Britain and Northern Ireland (the)") ? 'block' : 'none' }}>
            <Label htmlFor="" size={LabelSize.Medium} text="How do you identify?" />
            <Select
              onOptionsChange={(e: any) => {
                setLgbtq(e?.[0]);
              }}
              options={props?.lgbtqOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.lgbtq}
              status={errors?.lgbtqStatus}
            />
            {errors?.lgbtqMsg && (
              <span className="errorMessage">{errors?.lgbtqMsg}</span>
            )}
          </div>

          <div className="fieldWrap" style={{ display: (countryOfResidence === "United States of America (the)" || countryOfResidence === "United Kingdom of Great Britain and Northern Ireland (the)") ? 'block' : 'none' }}>
            <div className="tooltipText">
              <Label
                htmlFor=""
                size={LabelSize.Medium}
                text="Do you identify as being a part of any underrepresented group?"
              />
              <Tooltip
                animate
                classNames="my-tooltip-class"
                closeOnOutsideClick
                closeOnReferenceClick
                content={toooltipText}
                dropShadow
                hideAfter={200}
                id="myTooltipId"
                offset={8}
                openDelay={0}
                placement="bottom"
                portalId="my-portal-id"
                portalRoot={null}
                positionStrategy="absolute"
                tabIndex={-1}
                trigger="hover"
                visibleArrow
              >
                <img src={'../assets/img/materialIcon.svg'} alt="" />
              </Tooltip>
            </div>
            <Select
              onOptionsChange={(e: any) => {
                setDiversityNonSpecific(e?.[0]);
              }}
              options={props?.diversityNonSpecificOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.diversityNonSpecific}
              status={chkForError(errors?.diversityNonSpecificMsg)}
            />
            {errors?.diversityNonSpecificMsg && (
              <span className="errorMessage">
                {errors?.diversityNonSpecificMsg}
              </span>
            )}
          </div>         

          <div className="fieldWrap" style={{ display: (countryOfResidence === "United States of America (the)") ? 'block' : 'none' }}>
            <Label htmlFor="" size={LabelSize.Medium} text="Did you serve on active duty in the U.S. Military?" />
            <Select
              onOptionsChange={(e: any) => {
                setVeteranStatus(e?.[0]);
              }}
              options={props?.veteranStatusOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.veteranStatus}
              status={errors?.veterenStatusError}
            />
            {errors?.veteranStatusMsg && (
              <span className="errorMessage">{errors?.veteranStatusMsg}</span>
            )}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="" size={LabelSize.Medium} text="What is your Age?" />
              <Select
                onOptionsChange={(e: any) => {
                  setAge(e?.[0]);
                }}
                options={props?.ageOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.age}
                status={errors?.age}
              />
              {errors?.age && (
                <span className="errorMessage">{errors?.age}</span>
              )}
            </div>

          <div className="fieldWrap">
            <Label htmlFor="" size={LabelSize.Medium} text="What is the highest level of education you have completed?" />
            <Select
              onOptionsChange={(e: any) => {
                setEducation(e?.[0]);
              }}
              options={props?.educationOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.education}
              status={chkForError(errors?.educationMsg)}
            />
            {errors?.educationMsg && (
              <span className="errorMessage">{errors?.educationMsg}</span>
            )}
          </div>

          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              description="My icon description."
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              onClick={backtoCompanyPage}
              transparent={true}
            />
            <Button
              text="Submit"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                savePersonalData();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}
    </div >
  );
};

export default Personal;
