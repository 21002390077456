import {
  TextInput,
  Button,
  ButtonVariant,
  TextInputWidth,
  TextInputSize,
  LabelSize,
  Select,
  Label,
  SelectSize,
  DatePicker,
  DatePickerSize,
  Icon,
  IconName,
  ButtonIconAlign,
  EventValue
} from "@eightfold.ai/octuple";
import React, { useEffect, useState, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import dayjs from 'dayjs';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { globalContext } from "../../store";
import { checkSubDropDownValues, updateDemographicData } from '../../commons/commonFunctions'
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);


const Role = (props: any) => {

  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const [jobFunction, setJobFunction] = useState<any>("");
  const [jobSubFuncOptions, setJobSubFuncOptions] = useState<any>([]);
  const [jobSubFunction, setJobSubFunction] = useState<any>("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [yearsAtJobLevel, setYearsAtJobLevel] = useState("");
  const [yearsInLeadershipRole, setYearsInLeadershipRole] = useState("");
  const [directReports, setDirectReports] = useState("");
  const [errors, setErrors] = useState<any>({});

  const [disableSubFunc, setDisableSubFunc] = useState(true);
 
  const [UnformattedStartDate, setUnformattedStartDate] = useState<EventValue<dayjs.Dayjs> | null>(null);
  const [UnformattedEndDate, setUnformattedEndDate] = useState<EventValue<dayjs.Dayjs> | null>(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const notApplicableList = [{ text: 'Not Applicable', value: 'Not Applicable' }];

  useEffect(() => {
    if (props) {
      setJobLevel(props?.jobLevel);
      setJobTitle(props?.jobTitle);
      setYearsAtJobLevel(props?.yearsAtJobLevel);
      setJobFunction(props?.jobFunction);
      if (props.jobSubFunction) setJobSubFunction(props.jobSubFunction);
      setYearsInLeadershipRole(props?.yearsInLeadershipRole);
      setDirectReports(props?.directReports);
      if (props?.hireDate) {
        setUnformattedStartDate(dayjs(props?.hireDate))
        setStartDate(new Date(props?.hireDate));
      }
      if (props?.termminationDate) {
        setUnformattedEndDate(dayjs(props?.termminationDate))
        setEndDate(new Date(props.termminationDate));
      }
    }
  }, [props]);

  useEffect(() => {
    if (jobSubFuncOptions && (jobSubFuncOptions?.[0]?.text === 'Not Applicable')) {
      setJobSubFunction('Not Applicable');
    }
    else if (jobSubFuncOptions && (jobSubFuncOptions?.[0]?.text !== 'Not Applicable') && jobFunction && (jobSubFunction === 'Not Applicable')) {
      setJobSubFunction('');
    }

  }, [jobSubFuncOptions]);



  const changeSubFunction = (value: any) => {
    if (jobSubFunction != 'Not Applicable') {
      setJobSubFunction(value?.[0]);
    }
  };

  const changeJobFunction = (value: string) => {
    setJobFunction(value?.[0]);
    const options = checkSubDropDownValues(value?.[0], 'Job Sub-Function', globalState?.lookUpData);
    if (value?.[0] && options && options?.length > 0) {
      setJobSubFuncOptions(options);
      setDisableSubFunc(false);
    } else if (value?.[0]) {
      setJobSubFuncOptions(notApplicableList);
      setDisableSubFunc(true);
    }
    if (!value?.[0]) {
      setDisableSubFunc(true);
    }
  };

  

  const validateFormData = () => {
    const jobLevelMsg = validateFields.validate({ dropDown: jobLevel ? jobLevel : '' }, validationOptions)?.error?.details[0]?.message;
    const jobTitleMsg = validateFields.validate({ textField: jobTitle ? jobTitle : '' }, validationOptions)?.error?.details[0]?.message;
    const startDateMsg = validateFields.validate({ dropDown: (startDate && startDate !== 'Invalid Date') ? 'true' : '' }, validationOptions)?.error?.details[0]?.message;
    const jobFunctionMsg = validateFields.validate({ dropDown: jobFunction ? jobFunction : '' }, validationOptions)?.error?.details[0]?.message;
    const jobSubFunctionMsg = validateFields.validate({ dropDown: jobSubFunction ? jobSubFunction : '' }, validationOptions)?.error?.details[0]?.message;

    if (jobLevelMsg || jobTitleMsg || startDateMsg  || jobFunctionMsg || jobSubFunctionMsg) {
      setErrors({
        jobLevelMsg: jobLevelMsg,
        jobTitleMsg: jobTitleMsg,
        startDateMsg: startDateMsg,
        jobFunctionMsg: jobFunctionMsg,
        jobSubFunctionMsg: jobSubFunctionMsg
      });
      return true;
    } else {
      setErrors({});
      return false;
    }
  };

  const saveRoleData = () => {
    if (validateFormData()) return;
    const payload: any = {
      demographicSurveyData: {
        jobTitle: jobTitle,
        jobLevel: jobLevel,
        yearsAtJobLevel: yearsAtJobLevel,
        yearsInLeadershipRole: yearsInLeadershipRole,
        directReports: directReports,
        jobSubFunction: jobSubFunction,
        jobFunction: jobFunction,
        hireDate: startDate !== 'Invalid Date' ? startDate : '',
        termminationDate: endDate !== 'Invalid Date' ? endDate : ''
      }

    }
    if (startDate !== 'Invalid Date') {
      payload.demographicSurveyData['hireDate'] = startDate
    }

    if (endDate !== 'Invalid Date') {
      payload.demographicSurveyData['termminationDate'] = endDate;
    }

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        jobTitle: jobTitle,
        jobLevel: jobLevel,
        yearsAtJobLevel: yearsAtJobLevel,
        yearsInLeadershipRole: yearsInLeadershipRole,
        directReports: directReports,
        jobSubFunction: jobSubFunction,
        jobFunction: jobFunction,
        hireDate: startDate !== 'Invalid Date' ? startDate : '',
        termminationDate: endDate !== 'Invalid Date' ? endDate : ''
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext }
    const redirectUrl = '/register-company';
    updateDemographicData({payload, leaderProfileId : props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader});
  }

  const backtoAccountPage = () => {
    navigate('/register-account');
  }

  const chkForError = (condition: any) => {
    if (condition) {
      return 'error';
    } else {
      return 'validating';
    }
  }

  return (
    <div className="accountWrapper">
      <h1>Role</h1>
      <h2>About your current or most recent role</h2>
      {props?.jobLevelOptions?.length > 0 &&
        <>       
          <div className="fieldWrap">
            <TextInput
              htmlType="text"
              id="jobTitle"
              clearable
              labelProps={{
                text: "What is your job title?",
                size: LabelSize.Medium,
              }}
              maxlength={100}
              minlength={0}
              name="jobTitle"
              value={jobTitle}
              onChange={(e: any) => {
                setJobTitle(e.target.value);
              }}
              inputWidth={TextInputWidth.fill}
              classNames="inputTextField"
              size={TextInputSize.Large}
              status={chkForError(errors?.jobTitleMsg)}

            />
            {errors?.jobTitleMsg && <span className="errorMessage">{errors?.jobTitleMsg}</span>}

          </div>

          <div className="fieldWrap">
            <Label htmlFor="jobLevel" size={LabelSize.Medium} text="What is your job level?" />
            <Select
              id="jobLevel"
              data-test-id="jobLevel"
              defaultValue={props?.jobLevel}
              onOptionsChange={(e: any) => {
                setJobLevel(e?.[0]);
              }}
              size={SelectSize.Large}
              classNames="selectField"
              options={props?.jobLevelOptions}
              status={chkForError(errors?.jobLevelMsg)}
            />
            {errors?.jobLevelMsg && <span className="errorMessage">{errors?.jobLevelMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="startDate" size={LabelSize.Medium} text="Start Date" />
            <DatePicker
              id="startDate"
              size={DatePickerSize.Large}
              classNames="datePickerField"
              status={chkForError(errors?.startDateMsg)}
              value={UnformattedStartDate}
              onChange={(value: EventValue<dayjs.Dayjs> | null) => {
                setUnformattedStartDate(value);
                setStartDate(dayjs(value).format('YYYY-MM-DDTHH:mm:ss'));
              }}
            />
            {errors?.startDateMsg && <span className="errorMessage">{errors?.startDateMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="endDate" size={LabelSize.Medium} text="End Date" />
            <DatePicker
              id="endDate"
              size={DatePickerSize.Large}
              status={chkForError(errors?.endDateMsg)}
              value={UnformattedEndDate}
              onChange={(value: EventValue<dayjs.Dayjs> | null) => {
                setUnformattedEndDate(value);
                setEndDate(dayjs(value).format('YYYY-MM-DDTHH:mm:ss'));
              }}
              classNames="datePickerField"
            />
            {errors?.endDateMsg && <span className="errorMessage">{errors?.endDateMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="yearsAtJobLevel"
              size={LabelSize.Medium}
              text="How many years total have you been at your current job level?"
            />
            <Select
              id="yearsAtJobLevel"
              data-test-id="yearsAtJobLevel"
              onOptionsChange={(e: any) => {
                setYearsAtJobLevel(e?.[0]);
              }}
              size={SelectSize.Large}
              classNames="selectField"
              options={props?.yearsAtJobLevelOptions}
              defaultValue={props?.yearsAtJobLevel}
              status={chkForError(errors?.yearsAtJobLevelMsg)}
            />
            {errors?.yearsAtJobLevelMsg && <span className="errorMessage">{errors?.yearsAtJobLevelMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="jobFunction"
              size={LabelSize.Medium}
              text="What is your job function?"
            />
            <Select
              id="jobFunction"
              data-test-id="jobFunction"
              onOptionsChange={(value: any) => changeJobFunction(value)}
              size={SelectSize.Large}
              status={chkForError(errors?.jobFunctionMsg)}
              classNames="selectField"
              options={props?.jobFunctionOptions}
              defaultValue={props?.jobFunction}
            />
            {errors?.jobFunctionMsg && <span className="errorMessage">{errors?.jobFunctionMsg}</span>}

          </div>

          {jobSubFuncOptions?.length > 0 &&
            <div className="fieldWrap">
              <Label
                id="subfunction"
                size={LabelSize.Medium}
                text="What is your job function sub-function?"
              />
              <Select
                area-labelledby="subfunction"
                id="jobSubFunction"
                size={SelectSize.Large}
                onOptionsChange={(value: any) => changeSubFunction(value)}
                classNames="selectField"
                options={jobSubFuncOptions !== undefined ? jobSubFuncOptions : []}
                defaultValue={jobSubFunction ?? "Select"}
                status={chkForError(errors?.jobSubFunctionMsg)}
                disabled={disableSubFunc ? true : false}
              />
              {errors?.jobSubFunctionMsg && <span className="errorMessage">{errors?.jobSubFunctionMsg}</span>}
            </div>
          }



          <div className="fieldWrap">
            <Label
              htmlFor="yearsInLeadershipRole"
              size={LabelSize.Medium}
              text="How many years have you been in a leadership role?"
            />
            <Select
              id="yearsInLeadershipRole"
              data-test-id="yearsInLeadershipRole"
              onOptionsChange={(e: any) => {
                setYearsInLeadershipRole(e?.[0]);
              }}
              size={SelectSize.Large}
              classNames="selectField"
              options={props?.yearsInLeadershipRoleOptions}
              defaultValue={props?.yearsInLeadershipRole}
              status={chkForError(errors?.yearsInLeadershipRoleMsg)}
            />
            {errors?.yearsInLeadershipRoleMsg && <span className="errorMessage">{errors?.yearsInLeadershipRoleMsg}</span>}

          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="directReports"
              size={LabelSize.Medium}
              text="How many people directly report to you?"
            />
            <Select
              id="directReports"
              data-test-id="directReports"
              onOptionsChange={(e: any) => {
                setDirectReports(e?.[0]);
              }}
              size={SelectSize.Large}
              status={chkForError(errors?.directReportsMsg)}
              classNames="selectField"
              options={props?.directReportsOptions}
              defaultValue={props?.directReports}
            />
            {errors?.directReportsMsg && <span className="errorMessage">{errors?.directReportsMsg}</span>}
          </div>

      
          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              description="My icon description."
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              onClick={backtoAccountPage}
              transparent={true}
            />
            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => { saveRoleData() }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Role;
