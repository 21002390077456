import React, { useContext, useEffect, useState } from "react";
import Header from "../../commons/Header";
import {
  Col,
  Row,
  Stepper,
  StepperSize,
  StepperVariant,
} from "@eightfold.ai/octuple";
import Account from "./Account";
import Role from "./Role";
import Footer from "../../commons/Footer";
import Company from "./Company";
import Personal from "./Personal";
import { globalContext } from "../../store";
import Api from "../../Api";
import { checkDropDownValues } from "../../commons/commonFunctions";
import Loader from "../../commons/Loader";
import { useNavigate } from "react-router";

const Onboarding = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { globalState, dispatch } = useContext(globalContext);

  const title = globalState?.clientData?.name;

  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [yearsAtJobLevelOptions, setYearsAtJobLevelOptions] = useState([]);
  const [yearsInLeadershipRoleOptions, setYearsInLeadershipRoleOptions] = useState([]);
  const [directReportsOptions, setDirectReportsOptions] = useState([]);
  const [jobFunctionOptions, setJobFunctionOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);

  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [orgStructureOptions, setOrgStructureOptions] = useState([]);
  const [orgSizeOptions, setOrgSizeOptions] = useState([]);
  const [orgCycleOptions, setOrgCycleOptions] = useState([]);
  const [orgRevenueOptions, setOrgRevenueOptions] = useState([]);
  const [workingEnvironmentOptions, setWorkingEnvironmentOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [countryOfResidenceOptions, setCountryOfResidenceOptions] = useState('');
  const [genderOptions, setGenderOptions] = useState('');
  const [lgbtqOptions, setLgbtqOptions] = useState('');
  const [diversityNonSpecificOptions, setDiversityNonSpecificOptions] = useState('');
  const [veteranStatusOptions, setVeteranStatusOptions] = useState('');
  const [educationOptions, setEducationOptions] = useState('');
  const [ageOptions, setAgeOptions] = useState('');
  
  let loginToken: any = JSON.parse(
    sessionStorage.getItem("loginToken") ?? "false"
  );

  let clientId: any = JSON.parse(
    sessionStorage.getItem("clientId") ?? "false"
  );
 
  let fetchLeaderData = false;
  let fetchLookupData = false;

  const stepperArgs = {
    classNames: "my-stepper",
    id: "myStepperId",
    layout: "vertical",
    readonly: false,
    required: false,
    size: StepperSize.Medium,
    steps: [],
    variant: StepperVariant.Default,
    "data-test-id": "myStepperTestId",
    width: 100,
  };

  useEffect(() => {
    if (Object.keys(globalState?.leaderData).length === 0 || Object.keys(globalState?.lookUpData).length === 0) {
      getLeaderData();
    } else {
      if (globalState?.leaderData?.isProfileCompleted) {
        navigate('/dashboard');
      }
      setShowLoader(false);
    }
  }, []);

  const subListPrepared = (i: any) => {
    if (i?.dsSubList && i?.dsSubList.length > 0) {
      const subList = i?.dsSubList?.map((list: any) => {
        return {
          text: list.dsSubListEntry,
          value: list.dsSubListEntry,
        };
      })
      subList.unshift({ text: 'Select', value: '' });
      return subList
    }
  }

  const listPrepared = (item: any) => {
    const listWithSelect = item?.dsListEntries?.map((i: any) => {
      return {
        text: i.dsListEntry,
        value: i.dsListEntry,
        subList: subListPrepared(i)
      };
    })
    listWithSelect.unshift({ text: 'Select', value: '' });
    return listWithSelect
  }

  const getLookUpData = () => {
    
    const payload = {
      clientId: clientId,
    };
    if (fetchLookupData === false) {
      fetchLookupData = true;
      Api.getLookUp(payload)
        .then((response: any) => {
          if (response.success) {
            const responseData = response?.response?.data?.data?.[0]?.demographicSurveyLookups;
            const newData = responseData?.map((item: any) => {
              return {
                dropDownName: item.dsListName,
                dropDownValues: listPrepared(item)
              };
            });
            dispatch({
              type: "SET_LOOKUP_DATA",
              payload: newData,
            });
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }
  };

  const getLeaderData = () => {
      
    const payload = {
      email: loginToken?.idToken?.claims?.email.toLowerCase(),
      clientId: clientId,
    };

    if (fetchLeaderData === false) {
      fetchLeaderData = true;
      if (Object.keys(globalState?.leaderData).length === 0) {
        Api.getLeaderProfile(payload)
          .then((response: any) => {
            if (response.success) {
              const profileData = response?.response?.data?.data?.[0];
              dispatch({
                type: "SET_LEADER_DATA",
                payload: profileData,
              });
              if (profileData?.isProfileCompleted) {
                navigate('/dashboard');
              }
              getLookUpData();
            } else {
              setShowLoader(false);
            }
          })
          .catch((err) => {
            setShowLoader(false);
          });
      } else {
        getLookUpData();
      }
    }
  };

  useEffect(() => {
    setJobLevelOptions(checkDropDownValues('Job Level', globalState?.lookUpData));
    setYearsAtJobLevelOptions(checkDropDownValues('Years at Job Level', globalState?.lookUpData));
    setYearsInLeadershipRoleOptions(checkDropDownValues('Years in Leadership Role', globalState?.lookUpData));
    setDirectReportsOptions(checkDropDownValues('Direct Reports', globalState?.lookUpData));
    setJobFunctionOptions(checkDropDownValues('Job Function', globalState?.lookUpData));
    setIndustryOptions(checkDropDownValues('Industry', globalState?.lookUpData));
    setOrgTypeOptions(checkDropDownValues('Org Type', globalState?.lookUpData));
    setOrgStructureOptions(checkDropDownValues('Org Structure', globalState?.lookUpData));
    setOrgCycleOptions(checkDropDownValues('Cycle/Org Cycle', globalState?.lookUpData));
    setOrgSizeOptions(checkDropDownValues('Org Size', globalState?.lookUpData));
    setOrgRevenueOptions(checkDropDownValues('Revenue', globalState?.lookUpData));
    setWorkingEnvironmentOptions(checkDropDownValues('Working Environment', globalState?.lookUpData));
    setCountryOfResidenceOptions(checkDropDownValues('Country of Residence', globalState?.lookUpData));
    setGenderOptions(checkDropDownValues('Gender', globalState?.lookUpData));
    setLgbtqOptions(checkDropDownValues('LGBTQ', globalState?.lookUpData));
    setDiversityNonSpecificOptions(checkDropDownValues('Diversity, Non-Specific', globalState?.lookUpData));
    setVeteranStatusOptions(checkDropDownValues('Veteran Status', globalState?.lookUpData));
    setEducationOptions(checkDropDownValues('Education', globalState?.lookUpData));
    setAgeOptions(checkDropDownValues('Age',globalState?.lookUpData));

  }, [globalState?.lookUpData]);

  const accountProps = {
    firstName: globalState?.leaderData?.firstName,
    lastName: globalState?.leaderData?.lastName,
    email: globalState?.leaderData?.email,
    isPrivacyPolicyAccepted:globalState?.leaderData?.applications?.leaderPortal?.isPrivacyPolicyAccepted,
    leaderProfileId: globalState?.leaderData?.id
  }

  const roleProps = {
    jobTitle: globalState?.leaderData?.demographicSurveyData?.jobTitle,
    hireDate: globalState?.leaderData?.demographicSurveyData?.hireDate,
    jobRole: globalState?.leaderData?.demographicSurveyData?.jobRole,
    jobLevel: globalState?.leaderData?.demographicSurveyData?.jobLevel,
    jobFunction: globalState?.leaderData?.demographicSurveyData?.jobFunction,
    jobSubFunction: globalState?.leaderData?.demographicSurveyData?.jobSubFunction,
    yearsAtJobLevel: globalState?.leaderData?.demographicSurveyData?.yearsAtJobLevel,
    yearsInLeadershipRole: globalState?.leaderData?.demographicSurveyData?.yearsInLeadershipRole,
    directReports: globalState?.leaderData?.demographicSurveyData?.directReports,
  
    termminationDate: globalState?.leaderData?.demographicSurveyData?.termminationDate,
    leaderProfileId: globalState?.leaderData?.id,
    jobLevelOptions,
    yearsAtJobLevelOptions,
    yearsInLeadershipRoleOptions,
    directReportsOptions,
    jobFunctionOptions,
    
  }

  const companyProps = {
    industry: globalState?.leaderData?.demographicSurveyData?.industry,
    industrySector: globalState?.leaderData?.demographicSurveyData?.industrySector,
    orgType: globalState?.leaderData?.demographicSurveyData?.orgType,
    orgStructure: globalState?.leaderData?.demographicSurveyData?.orgStructure,
    orgSize: globalState?.leaderData?.demographicSurveyData?.orgSize,
    orgCycle: globalState?.leaderData?.demographicSurveyData?.orgCycle,
    orgRevenue: globalState?.leaderData?.demographicSurveyData?.orgRevenue,
    workingEnvironment: globalState?.leaderData?.demographicSurveyData?.workingEnvironment,
    leaderProfileId: globalState?.leaderData?.id,
    orgTypeOptions,
    orgStructureOptions,
    orgSizeOptions,
    orgCycleOptions,
    orgRevenueOptions,
    workingEnvironmentOptions,
    industryOptions
  }

  const personalProps = {
    gender: globalState?.leaderData?.demographicSurveyData?.gender,
    ethnicity: globalState?.leaderData?.demographicSurveyData?.ethnicity,
    lgbtq: globalState?.leaderData?.demographicSurveyData?.lgbtq,
    diversityNonSpecific: globalState?.leaderData?.demographicSurveyData?.diversityNonSpecific,
    veteranStatus: globalState?.leaderData?.demographicSurveyData?.veteranStatus,
    education: globalState?.leaderData?.demographicSurveyData?.education,
    age: globalState?.leaderData?.demographicSurveyData?.age,
    countryOfResidence: globalState?.leaderData?.demographicSurveyData?.countryOfResidence,
    leaderProfileId: globalState?.leaderData?.id,
    timeZone: globalState?.leaderData?.demographicSurveyData?.timeZone,
    countryOfResidenceOptions,
    genderOptions,
    lgbtqOptions,
    diversityNonSpecificOptions,
    veteranStatusOptions,
    educationOptions,
    ageOptions,
  }

  const areFieldsPresent = (obj: any, fields: any) => {
    return fields.every((field: any) => field.split('.').reduce((o: any, k: any) => (o || {})[k], obj));
  }

  const accountFields = ['firstName', 'lastName', 'email', 'applications.leaderPortal.isPrivacyPolicyAccepted'];
  const roleFields = [
    'demographicSurveyData.jobTitle',
    'demographicSurveyData.hireDate',
    'demographicSurveyData.jobLevel',
    'demographicSurveyData.jobFunction',
    'demographicSurveyData.jobSubFunction',
    'demographicSurveyData.industry',
    'demographicSurveyData.industrySector'
  ];
  const companyFields = [
    'demographicSurveyData.orgType',
    'demographicSurveyData.orgStructure',
    'demographicSurveyData.orgSize',
    'demographicSurveyData.orgCycle',
    'demographicSurveyData.orgRevenue',
    'demographicSurveyData.workingEnvironment'
  ];
  const personalFields = [
    'demographicSurveyData.gender',
    'demographicSurveyData.countryOfResidence',
    'demographicSurveyData.ethnicity',
    //'demographicSurveyData.lgbtq',
    //'demographicSurveyData.veteranStatus'
  ];


  const workflow = [
    {
      index: 1,
      content: (pathname.split("/")[1] === 'register-account') ? <Account  {...accountProps} /> : <h1 className='inActiveSteps'>Account</h1>,
      complete: areFieldsPresent(globalState?.leaderData, accountFields)
    },
    {
      index: 2,
      content: (pathname.split("/")[1] === 'register-role') ? <Role {...roleProps} /> : <h1 className='inActiveSteps'>Role</h1>,
      complete: areFieldsPresent(globalState?.leaderData, roleFields)
    },
    {
      index: 3,
      content: (pathname.split("/")[1] === 'register-company') ? <Company {...companyProps} /> : <h1 className='inActiveSteps'>Company</h1>,
      complete: areFieldsPresent(globalState?.leaderData, companyFields)
    },
    {
      index: 4,
      content: (pathname.split("/")[1] === 'register-personal') ? <Personal {...personalProps} /> : <h1 className='inActiveSteps'>Personal</h1>,
      complete: areFieldsPresent(globalState?.leaderData, personalFields)
    },
  ];

  const activeIndex = () => {
    switch (pathname.split("/")[1]) {
      case 'register-account':
        return 0;
      case 'register-role':
        return 1;
      case 'register-company':
        return 2;
      case 'register-personal':
        return 3;
      default:
        return 0;
    }
  }

  return (
    <div className='mainWrapper'>
      {showLoader && <Loader />}
      <div className="imgWrapper" style={{ background: 'url(./../assets/img/onboarding-image.png) no-repeat' }}>
        &nbsp;
      </div>
      <div className='rightWrapper'>
        <Header title={title} />

        <Row classNames="formWrapper">
          <Col span='12'>
            <Stepper
              {...stepperArgs}
              index={activeIndex()}
              steps={workflow}
              layout='vertical'
              scrollToActiveStep
              classNames='stepperContainer'
            />
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
};

export default Onboarding;