import React, { useState } from "react";
import { List, Dropdown, Icon, Avatar, IconName, Navbar, NavbarContent } from "@eightfold.ai/octuple";
import { useNavigate } from "react-router";
import { useOktaAuth } from '@okta/okta-react';

const sampleList: string[] = ['About', 'Log Out'];

const Overlay = (props: any) => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const gotoWelcome = (e: any) => {
    if (e.target.innerText === 'About') {
      navigate('/');
    } else if (e.target.innerText === 'Log Out') {
      sessionStorage.clear();
      props.setLoader(true);
      if (oktaAuth) {
        oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin
        });
      }
    }
  }
  return (
    <List
      items={sampleList}
      layout="vertical"
      classNames="list"
      onClick={gotoWelcome}
      renderItem={(item: string) => <div style={{ margin: '8px', cursor: 'pointer' }}>{item}</div>}
    />
  );
};

const ProfileDropdown = (props: any) => {
  const [,setVisibility] = useState(false);
  return (
    <Dropdown
      onVisibleChange={(isVisible) => setVisibility(isVisible)}
      overlay={<Overlay setLoader={props.setLoader} />}
      dropdownStyle={{ color: '#212121', top: '79px' }}
      classNames="dropdownclass"
    >
      <div className="divclass" style={{ display: 'flex', top: '79px' }}>
        <Avatar
          classNames="my-avatar-class"
          data-test-id="my-avatar-test-id"
          fontSize="18px"
          size="40px"
          style={{}}
          theme="grey"
          type="round"
        >
          GB
        </Avatar>
        <Icon path={IconName.mdiMenuDown} />
      </div>
    </Dropdown>
  );
};

const AuthHeader = (props: any) => {
  return (
    <div className="authHeader">
      <Navbar classNames="authHeaderLogo">
        <NavbarContent>
          <h4 className="authHeaderTitle">{props.title}</h4>
          <div className='authDividerHeader'></div>
          <h4 className='headerTitle'>Leader Portal</h4>
        </NavbarContent>
        
        <NavbarContent>
          <ProfileDropdown setLoader={props.setLoader} />
        </NavbarContent>
      </Navbar>
    </div>
  );
};

export default AuthHeader;